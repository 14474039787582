<template>
  <b-card title="Ekspertiz Bilgisi">
    <b-list-group>
      <list-item
        v-for="(item,key) in swapcar.expertise"
        :key="key"
        :title="getValue(key,item).title"
        :value="getValue(key,item).value"
      />
    </b-list-group>
  </b-card>
</template>
<script>
import { BCard, BListGroup } from 'bootstrap-vue'
import ListItem from '@/views/Expertise/Components/ListItem.vue'

export default {
  name: 'Expertise',
  components: {
    BCard, BListGroup, ListItem,
  },
  data() {
    return {
      options: [
        '1. Sağ Arka Çamurluk',
        '2. Arka Bagaj Kapağı',
        '3. Sol Arka Çamurluk',
        '4. Sağ Arka Kapı',
        '5. Sağ Ön Kapı',
        '6. Tavan',
        '7. Sol Arka Kapı',
        '8. Sol Ön Kapı',
        '9. Sağ Ön Çamurluk',
        '10. Motor Kaputu',
        '11. Sol Ön Çamurluk',
      ],
      values: [
        'Orijinal',
        'Boyalı',
        'Değişmiş',
      ],
    }
  },
  computed: {
    swapcar() {
      return this.$store.getters['swapcars/getSwapcar']
    },
  },
  methods: {
    getValue(key, value) {
      const data = {
        title: this.options[key],
        value: this.values[Number(value) - 1],
      }
      return data
    },
  },
}
</script>

<style scoped></style>
