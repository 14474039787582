<template>
  <div>
    <b-row>
      <b-col
        cols="12"
        md="6"
      >
        <public />
      </b-col>
      <b-col
        cols="12"
        md="6"
      >
        <request-detail />
      </b-col>
      <b-col
        cols="12"
        md="12"
      >
        <expertise v-if="dataItem.expertise.length > 0" />
      </b-col>
      <b-col
        cols="12"
        md="12"
      >
        <swap-price />
      </b-col>
    </b-row>
  </div>
</template>
<script>
import { BRow, BCol } from 'bootstrap-vue'
import Public from '@/views/Expertise/Components/Public.vue'
import RequestDetail from '@/views/Expertise/Components/RequestDetail.vue'
import Expertise from '@/views/Expertise/Components/Expertise.vue'
import SwapPrice from '@/views/Expertise/Components/PriceCard.vue'

export default {
  name: 'View',
  components: {
    SwapPrice, Expertise, RequestDetail, Public, BRow, BCol,
  },
  computed: {
    dataItem() {
      return this.$store.getters['swapcars/getSwapcar']
    },
  },
  created() {
    this.getDataItem()
  },
  methods: {
    getDataItem() {
      return this.$store.dispatch('swapcars/swapcarView', {
        id: this.$route.params.id,
        license_plate: this.$route.params.license_plate,
      })
    },
  },
}
</script>
