<template>
  <b-card title="Talep Bilgileri">
    <b-list-group>
      <list-item
        title="Ekspertiz Talep Eden"
        :value="swapcar.user_request"
      />
      <list-item
        title="Takas Bedeli"
        :value="swapcar.swap_price ? swapcar.swap_price + ' TL' : 'Belirlenmedi'"
      />
      <list-item
        title="Talep Edilen Bedel"
        :value="swapcar.request_price + ' TL'"
      />
      <list-item
        title="Eksper Notu"
        :value="swapcar.expertise_report"
      />
      <list-item
        title="Tramer"
        :value="swapcar.tramer"
      />
      <list-item
        title="Notlar"
        :value="swapcar.notes"
      />
    </b-list-group>
  </b-card>
</template>
<script>
import { BCard, BListGroup } from 'bootstrap-vue'
// eslint-disable-next-line import/extensions
import ListItem from '@/views/Expertise/Components/ListItem.vue'

export default {
  name: 'RequestDetail',
  components: {
    BCard, BListGroup, ListItem,
  },
  computed: {
    swapcar() {
      return this.$store.getters['swapcars/getSwapcar']
    },
  },
}
</script>

<style scoped></style>
