<template>
  <b-card title="Takas Bedeli">
    <validation-observer ref="simpleRules">
      <b-form-group
        label="Takas Bedeli"
        label-for="swap_price"
      >
        <validation-provider
          #default="{ errors }"
          name="Takas Bedeli"
          rules="required"
        >
          <cleave
            id="swap_price"
            v-model="swapcar.swap_price"
            class="form-control"
            :raw="true"
            :options="options.price"
            placeholder="Giriniz.."
          />
          <small class="text-danger">{{ errors[0] }}</small>
        </validation-provider>
      </b-form-group>
      <b-button
        variant="primary"
        class="btn-block mb-2"
        :disabled="savingPrice"
        @click="savePrice"
      >
        Kaydet
      </b-button>
    </validation-observer>
  </b-card>

</template>

<script>
import { ValidationProvider, ValidationObserver, localize } from 'vee-validate'
import {
  BFormGroup, BCard, BButton,
} from 'bootstrap-vue'
import Cleave from 'vue-cleave-component'
// eslint-disable-next-line no-unused-vars
import { required } from '@validations'

export default {
  name: 'SwapPrice',
  components: {
    BFormGroup,
    Cleave,
    ValidationProvider,
    ValidationObserver,
    BCard,
    BButton,
  },
  data() {
    return {
      savingPrice: false,
      options: {
        price: {
          numeral: true,
          numeralThousandsGroupStyle: 'thousand',
          numeralDecimalMark: ',',
          delimiter: '.',
        },
      },
    }
  },
  computed: {
    swapcar() {
      return this.$store.getters['swapcars/getSwapcar']
    },
  },
  created() {
    localize('tr')
  },
  methods: {
    savePrice() {
      this.$refs.simpleRules.validate().then(success => {
        if (success) {
          this.savingPrice = true
          this.$store.dispatch('swapcars/swapcarSave', {
            id: this.swapcar.id,
            swap_price: this.swapcar.swap_price,
          }).then(response => {
            if (response.status) {
              this.$swal({
                icon: 'success',
                title: 'İşlem Başarılı',
                text: 'Araç için belirlenen takas fiyatı kaydedildi.',
                confirmButtonText: this.$store.state.app.removeResultClose,
                customClass: {
                  confirmButton: 'btn btn-success',
                },
              })
            } else {
              this.$swal({
                icon: 'error',
                title: 'İşlem Başarısız!',
                text: response.message,
                confirmButtonText: this.$store.state.app.removeResultClose,
                customClass: {
                  confirmButton: 'btn btn-success',
                },
              })
            }
            this.savingPrice = false
          })
        }
      })
    },
  },
}
</script>

<style scoped></style>
