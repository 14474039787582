<template>
  <b-card title="Araç Bilgisi">
    <b-list-group>
      <list-item
        title="Model Yılı"
        :value="swapcar.model_year"
      />
      <template>
        <list-item
          title="Marka"
          :value="swapcar.swap_brand"
        />
        <list-item
          title="Model"
          :value="swapcar.swap_model"
        />
      </template>
      <list-item
        title="Kasa Tipi"
        :value="swapcar.swapcar_type"
      />
      <list-item
        title="Silindir Hacmi"
        :value="swapcar.cc"
      />

      <list-item
        title="Motor Gücü (Hp)"
        :value="swapcar.hp"
      />
      <list-item
        title="Donanım Paketi"
        :value="swapcar.hardware"
      />
      <list-item
        title="Km"
        :value="swapcar.km"
      />
      <list-item
        title="Yakıt Tipi"
        :value="swapcar.fuel"
      />
      <list-item
        title="Vites"
        :value="swapcar.gear"
      />
      <list-item
        title="Renk"
        :value="swapcar.color"
      />
      <list-item
        title="Plaka"
        :value="swapcar.license_plate"
      />
      <!-- old-->

    </b-list-group>
  </b-card>
</template>
<script>
import { BCard, BListGroup } from 'bootstrap-vue'
import ListItem from '@/views/Expertise/Components/ListItem.vue'

export default {
  name: 'Public',
  components: {
    BCard, BListGroup, ListItem,
  },
  computed: {
    swapcar() {
      return this.$store.getters['swapcars/getSwapcar']
    },
  },
}
</script>

<style scoped></style>
